ul.breadcrumb {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: block;
}

ul.breadcrumb li {
    font-size: 0.8em;
}

ul.breadcrumb li, ul.breadcrumb li * {
    display: inline-block;
}

ul.breadcrumb li * {
    margin: 0;
    padding: 0;
}

ul.breadcrumb li::before {
    content: "/";
    padding: 0 0.25em;
}

.footer p {
    margin-top: 0;
    margin-bottom: 0;
}

.content.central {
    max-width: 800px;
    margin: 0 auto;
}

.content hgroup p {
    font-style: italic;
    font-size: 0.8em;
    color: #666;
    margin-top: 0.25em;
}

.content hgroup h2 {
    margin-bottom: 0;
}

.paraphernalia {
    font-size: 0.8em;
    margin-bottom: 2em;
}

.paraphernalia * {
    margin: 0;
    padding: 0;
    line-height: 1.25em;
}

.content article {
    margin-bottom: 1em;
}

.flex-wrapper {
    display: flex;
}

nav.side-nav {
    width: 100px;
    border-right: 1px dotted #666;
}

.content.side {
    flex: 1 0 auto;
    padding-left: 1em;
    max-width: calc(100% - 100px);
}

nav.side-nav ul {
    list-style-type: none;
    margin-right: 1em;
}

.bottom-nav {
    display: flex;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.half {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.bottom-nav .left-half {
    text-align: left;
    border-right: 1px dotted #666;
}

.bottom-nav .right-half {
    text-align: right;
}

img {
    max-width: 80%;
    text-align: center;
    margin: auto;
    display: block;
}

image[alt=equation] {
    height: 1em;
}

img[alt~="small-pic"] {
    max-width: 50%;
}

img[alt~="tiny-pic"] {
    max-width: 30%;
}

img[alt~="inline"] {
    display: inline-block;
    max-width: 100%;
    margin: 0;
}

article table {
    width: 100%;
}

article th {
    margin: 0 auto;
}

.tag-header {
    text-align: center;
}

.top-nav {
    display: block;
    width: 100%;
    margin-bottom: 3em;
}

.top-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
}

.top-nav li {
    flex: 1;
    text-align: center;
    border-left: 1px dotted #666;
}

.top-nav li:first-child {
    border-left: transparent;
}

/* Subtitles */
article td:has(img), article th:has(img), article tr:first-child:last-child td {
    text-align: center;
}
article tr:has(td > img) + tr td {
    text-align:center;
}